

.product .thumb .image img{
    /* height: 90%; */
    width: auto;
    max-width: 90%;
    border-radius: 10px;
}

.product {
    border-radius: 10px;
    overflow: hidden;
    background-color: #ffffffa8;
    padding-bottom: 5px;
}
.product .content{
    background-color: #ffffff29;
    padding: 5px 8px 0;
}
.product .content .ext_addto {
    margin-bottom: 0px;
}

/* 
.swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size)/ 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color,var(--swiper-theme-color));
} */
.page__title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding:20px; */
}
.slider-cover__prev::after {
    font-size: 1rem !important;
    color: #000 !important;
}
.slider-cover__next::after {
    font-size: 1rem !important;
    color: #000 !important;
}
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    content: 'prev';
}
.page__title-right {
    position: relative;
    display: flex;
    align-items: center;
}
/* .slider-cover__prev {
    position: relative;
    right: auto;
    left: auto;
    top: auto;
    bottom: 0px;
    margin-right: 10px;
} */
/* .slider-cover__next {
    position: relative;
    right: auto;
    left: auto;
    top: auto;
    bottom: 0px;
} */
/* .swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none!important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
} */
/* .f-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
*/
.f-wrapper-col{
    /* flex: 1;
    background: orange;
    margin: 1px;
    display: flex;
    justify-content: center; */
   
}

.f-col-item{
   
    /* flex: 1; */
    /* align-self: stretch; */
    /* height: max-content; */
   
}

@media only screen and (max-width: 600px){
    .content-h{
        /* min-height: 175px; */
    }
}
@media only screen and (min-width: 700px){
    .content-h{
        /* min-height: 185px; */
    }
    .pro-img-div{
        height: 250px;
    }
}
/* .swiper-container{
    padding-left: 2px;
    padding-right: 2px;
} */
.product .content .price:not(:last-child) {
    margin-bottom: 5px;
}
.product .content .title{
    margin-bottom: 7px 0 7px !important;
}
@media screen and (max-width: 700px) {
    .product .content .title, .product .content .title a{
     font-size: 13px;
    }
    .product .content .price span.old {
        font-size: 10px;
        margin-left: 10px;
        text-decoration: line-through;
        color: #ff0009;
    }

}

.product .content .price span.old {
    font-size: 14px;
    margin-left: 10px;
    text-decoration: line-through;
    color: #ff0009;
}
.badges span.new {
    background-color: #0266a9cf;
    padding:5px 5px;
    border-radius: 10px;
    font-size:12px;
}
.badges span.new2 {
    background-color: #ca03039e;
    padding:5px 5px;
    border-radius: 10px;
    font-size:12px;
}  
span.new a{
    color:#fff;
}