@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&amp;display=swap");
/* @import url(https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css); */
@import url(./assets/css/vendor/vendor.min.css);
@import url(./assets/css/plugins/plugins.min.css);
@import url(./assets/css/style.min.css);
@import url(./assets/css/aos.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
/* @import url(https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css); */
/* @import url('https://fonts.googleapis.com/css2?family=STIX+Two+Text&display=swap'); */
/* @import url(./assets/NeueMontreal-Regular.otf); */
.dot-carousel {
    position: relative;
    left: -9999px;
    width: 10px !important;
    height: 10px !important;
    border-radius: 5px !important;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    animation: dotCarousel 1.5s infinite linear;
}

@keyframes dotCarousel {
  0% {
    box-shadow: 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff;
  }
  50% {
    box-shadow: 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff;
  }
  100% {
    box-shadow: 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff;
  }
}
.ext_addto{
  visibility: visible;
  transform: translateY(0);
  opacity: 1;

  background-color: #4c9090 ;
  color: #FFFFFF;
  display: block;
  width: 80%;
  height: 45px;
  line-height: 45px;
  
  text-align: center;
  border: 0;
  border-radius: 3px;
  font-size: 14px;
  position: relative;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 11;
  margin: auto;
  font-weight: 600;
  /* visibility: hidden; */
  transition: all .3s ease 0s;
  transform: translateY(20px);
  /* color: #3f3f3f; */
  /* opacity: 0; */
  font-family: Raleway,sans-serif;
  flex-wrap: wrap-reverse;
}
.ti-filter::before {
  content: "\54";
}
.row>*{
  padding-right: 0px;
  padding-left: 0px;
}
.g-box-shadow{
  box-shadow:0 0 3px 0px rgb(2 2 2 / 5%);
  -webkit-box-shadow: 0 0 3px 0px rgb(2 2 2 / 5%);

}
.container-main {
  /* max-width: 1600px !important;
  text-align: center; */
}
.cart-main-area .table-content table tbody>tr td{
  padding:0px;
}
.mini-img-responsive{
  max-width: 55%;
    display: block;
}

/* revise kart plus minus */

.cart-main-area .table-content table tbody>tr td.product-quantity .cart-plus-minus_my {
  display: inline-block;
  height: 40px;
  padding: 0;
  position: relative;
  width: 110px;
}

.cart-main-area .table-content table tbody>tr td.product-quantity .cart-plus-minus_my .dec.qtybutton_my {
  border-right: 1px solid #e5e5e5;
  height: 40px;
  left: 0;
  padding-top: 8px;
  top: 0;
}

.cart-main-area .table-content table tbody>tr td.product-quantity .cart-plus-minus_my .qtybutton_my {
  color: #474747;
  cursor: pointer;
  float: inherit;
  font-size: 16px;
  margin: 0;
  position: absolute;
  transition: all .3s ease 0s;
  width: 20px;
  text-align: center;
}

.cart-main-area .table-content table tbody>tr td.product-quantity .cart-plus-minus_my input.cart-plus-minus-box {
  color: #474747;
  float: left;
  font-size: 14px;
  height: 40px;
  margin: 0;
  width: 110px;
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e1e1e1;
  padding: 0;
  text-align: center;
}

.cart-main-area .table-content table tbody>tr td.product-quantity .cart-plus-minus_my .inc.qtybutton_my {
  border-left: 1px solid #e5e5e5;
  height: 40px;
  padding-top: 9px;
  right: 0;
  top: 0;
}

.cart-main-area .table-content table tbody>tr td.product-quantity .cart-plus-minus_my .qtybutton_my {
  color: #474747;
  cursor: pointer;
  float: inherit;
  font-size: 16px;
  margin: 0;
  position: absolute;
  transition: all .3s ease 0s;
  width: 20px;
  text-align: center;
}
/* end of cart page */
.border-right{
  border-right: 5px solid red;
}
.cart-main-area .table-content table thead>tr>th {
  border-top: medium none;
  color: #212121;
  font-size: 14px;
  font-weight: 700;
  padding: 21px 22px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
}
.bg-light{
  background-color: #f8f9fa!important;
}
input[type=radio] {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}


@media (max-width: 768px){
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
}
.product .thumb .image img{
  max-height: 110px;
}
.small, small {
  font-size: 0.7rem;
}
}


@media (max-width: 476px){
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
}
.product .thumb .image img{
  max-height: 150px;
}
.small, small {
  font-size: 0.6rem;
}
}
@media (min-width:700px){
  .small, small {
    font-size: 0.8rem;
  }
}
.product .thumb .badges span.out {
  background-color: #9f1111;
}
.badges span.out{
  background-color: #9f1111;
  padding: 10px 10px;
    border-radius: 23px;
    color:#fff;
}
.fl-left{
  justify-content: left;
}
.fl-right{
  justify-content: right;
}
.product .thumb .image{
  text-align: center;
}
.box-smpad{
  display: flex;
    flex-direction: column;
    transition: all ease 0.4s;
    height: auto;
    border-radius: 5px;
    padding: 6px 8px;
    background: #ffffff00;
    margin-bottom: 30px;
    box-shadow: 0 0 3px 0px rgb(2 2 2 / 0%);
    -webkit-box-shadow: 0 0 3px 0px rgb(2 2 2 / 0%);
    padding-bottom: 0px;
}
.bg-area1{
  background-image: url('./assets/images/Frame-13.png.bv.webp');
}
.bg-area-astro{
  background-image: url('./assets/images/Frame-13.png.bv.webp');
  /* background-image: url('./assets/images/bg_technology.jpg');; */
}
.center{
  text-align: center;
}
label{
  margin-bottom: 0px;
}
.form-control{
  padding: 0px 0px;
}
.btn{
  width: auto;
  min-width: 150px;
  padding-left: 10px;
  padding-right: 10px;
}
.bg-color3{
  background-color: #099AB1;
}
.display-overflow{
  overflow: visible !important;
  display: block;
  position: relative;
}
.br-5{
  border-radius: 5px;
}
.overflow-scroll{
  overflow-x: scroll;
}
.round-image{
  border-radius: 50% !important;
  max-height: 150px;
  width: auto !important;
}
table{
  max-width: 100%;
  overflow-x: scroll;
}
input[readonly] {
  background-color: #f0f0f0; /* Your desired background color */
}



